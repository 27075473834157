import React from "react";
import ToolsResources_Hero from "../../../assets/img/ToolsResources_Hero.png";
import Tools_Current_promotions from "../../../assets/img/Tools_Current_promotions.png";
import Tools_Printed_sales_materials from "../../../assets/img/Canada Catalogues.jpg";
import DFS_TSB_Banner_thumb from "../../../assets/img/DFS_TSB_Banner_thumb.png";

import styles from "../style/styles.module.css";
import { PREVIEW } from "../../../project-config";

export default function ToolsAndResources() {
  return (
    <div className={styles.container}>
      <div className={styles.rowSale}>
        <div className={styles.contentSale}>
          <h1 className={styles.title}>Tools & Resources</h1>
          <h2>Professional marketing support that helps you succeed.</h2>
          <p className={styles.toolParag}>
            Boost sales with our digital and print marketing tools.
          </p>
          <img
            className={styles.hero}
            src={ToolsResources_Hero}
            alt="Woman sitting in fron of a computer"
          />

          <p className={styles.productMargin}>
            We know your day is consumed by taking care of customers, and that
            you typically don't have the time or staff to market your products
            and business at the level you'd like.
          </p>
          <p>
            We provide DFS Dealers with easy-to-use marketing resources that
            help you market in the moment and get more orders. Access it all
            here!
          </p>
        </div>
        <div className={styles.sidepanel}>
          <h3 style={{ fontSize: "1.33em" }}>Do business online with DFS</h3>
          <p>See why thousands of Dealers use DFSonline.ca</p>
          <a
            href={`${PREVIEW}/register.html?vid=20090722001&mt=1&ml=en`}
            className={styles.btn}
          >
            Create Online Account
          </a>
          <hr className={styles.toolMargin} />
          <h3>Contact DFS</h3>
          <p>Have a question?</p>
          <p>
            Please call us at{" "}
            <a href="tel:800-490-6327" className={styles.toolColor}>
              800.490.6327
            </a>
          </p>
          <p>
            Or visit our{" "}
            <a href="/contact">
              <span className={styles.toolColor}>Contact Us</span>
            </a>{" "}
            page
          </p>
        </div>
      </div>
      <hr />
      <div className={styles.row}>
        <h2>Our Online and Offline Sales & Marketing Tools</h2>
        <div className={styles.cardsSales}>
          <div className={styles.cardSales}>
            <img src={Tools_Current_promotions} alt="DFS promotions"></img>
            <a href="/sales-resources/promotions/">Current Promotions</a>
          </div>
          <div className={styles.cardSales}>
            <img
              src={Tools_Printed_sales_materials}
              alt="Printed sales materials"
            ></img>
            <a href="/sales-resources/sales-material/">Sales Tools</a>
          </div>
          <div className={styles.cardSales}>
            <img src={DFS_TSB_Banner_thumb} alt="Trade show items"></img>
            <a href="/sales-resources/trade-show-events/">
              Trade Shows & Events
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
