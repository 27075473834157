import React from "react";
import Layout from "../../other/layout";
import ToolsAndResources from "../../components/AC-StaticPages/sales-resources/ToolsAndResources";
import SEO from "../../other/seo";

export default function salesResources() {
  return (
    <Layout>
      <SEO title="Tools and Resources" />

      <ToolsAndResources />
    </Layout>
  );
}
